import { combineReducers } from 'redux'

import { profitAndLossReducer } from './profit_and_loss'
import expenses from './expenses'
import profitOverview from './profit_overview'

export default combineReducers({
  expenses,
  profitAndLoss: profitAndLossReducer,
  profitOverview
})
